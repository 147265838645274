import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import emailjs from 'emailjs-com';
import ReactToPrint from 'react-to-print';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';
import PrintOut from './print-out';
import FieldsForm from '../schema-parsers/fields-form';
import FieldsEmail from '../schema-parsers/fields-email';
import Disclaimer from './disclaimer';

class SellerNetForm extends React.Component {

    constructor(props) {

        super(props);

        this.formBody = FieldsForm(props.schema);
    }

    render() {

        return (
            <div className='vtg-form-wrap'>

                <h1 className='vtg-app-header'>Estimated Seller Net Proceeds</h1>

                {this.formBody.map((row, rowIndex) => {
                    return (
                        <div key={`row-${rowIndex}`} className={`vtg-row vtg-row-${row.length}`}>
                            {row.map(field => field)}
                        </div>
                    )
                })}

                <div className='vtg-buttons'>
                    <Button
                        onClick={(e) => onClickSubmit(this.props)}
                        color='green'
                    >Email Results</Button>

                    <ReactToPrint
                        trigger={() => <Button
                            color='blue'
                            inverted
                        >Print</Button>}
                        content={() => this.componentRef}
                    />

                    <Button
                        onClick={(e) => onClickReset(this.props)}
                        color='red'
                        inverted
                    >Reset</Button>
                </div>

                <Disclaimer />

                <Dimmer className='vtg-dimmer' active={this.props.loading}>
                    <Loader>{this.props.loaderText}</Loader>
                </Dimmer>

                <Modal
                    open={this.props.modalOpen}
                    onClose={() => hideMessage(this.props)}
                    size='small'
                >
                    <Modal.Content>
                        <h3>{this.props.messageHeader}</h3>

                        <p>{this.props.messageBody}</p>

                        <Button color='blue' onClick={() => hideMessage(this.props)}>
                            Continue
                </Button>
                    </Modal.Content>
                </Modal>

                <div style={{ display: 'none' }}>
                    <PrintOut schema={this.props.schema} ref={el => (this.componentRef = el)} />
                </div>
            </div>
        )
    }
};

/**
 * Set a message and show the message modal.
 * 
 * @param {object} props
 * @param {string} header - Header text to display in message modal
 * @param {string} body - Body text to display in message modal
 */
const showMessage = (props, header, body) => {
    props.changeModalText(header, body);
    props.changeModalState(true);
};

/**
 * Hide the message modal and reset the message.
 * 
 * @param {object} props
 */
const hideMessage = (props) => {
    props.changeModalState(false);
    props.changeModalText(null, null);
}

/**
 * Click handler for form submission.
 * 
 * @param {object} props
 */
const onClickSubmit = (props) => {
    let templateParams = {
        user_name: `${props.state.form[actions.FIRST_NAME]} ${props.state.form[actions.LAST_NAME]}`,
        user_email: props.state.form[actions.EMAIL],
        message_html: FieldsEmail(props.state)
    };

    props.changeLoaderState(true);

    emailjs.send('default_service', 'vtg_sellers_net_proceeds', templateParams, 'user_bFjld4MhR9C6g1l82iRp5')
        .then((response) => {
            props.changeLoaderState(false);
            showMessage(props, actions.MESSAGE_SUCCESS_HEADER, actions.MESSAGE_SUCCESS_BODY);
        }, (err) => {
            props.changeLoaderState(false);
            showMessage(props, actions.MESSAGE_ERROR_HEADER, actions.MESSAGE_ERROR_BODY);
        });
};

/**
 * Click handler for reset button.
 * 
 * @param {object} props
 */
const onClickReset = (props) => {
    props.resetForm();
}

const mapStateToProps = (state, props) => {
    return {
        loading: state.loading,
        loaderText: state.loaderText,
        modalOpen: state.modalOpen,
        messageHeader: state.messageHeader,
        messageBody: state.messageBody,
        schema: state.schema,
        state: state
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        changeLoaderState: (bool) => {
            dispatch(actions.changeLoaderState(bool));
        },
        changeLoaderText: (text) => {
            dispatch(actions.changeLoaderText(text));
        },
        changeModalState: (bool) => {
            dispatch(actions.changeModalState(bool));
        },
        changeModalText: (header, body) => {
            dispatch(actions.changeModalText({
                messageHeader: header,
                messageBody: body
            }));
        },
        resetForm: () => {
            dispatch(actions.resetForm());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SellerNetForm);