import React from 'react';

const PrintField = (props) => (
    <span className='vtg-print-field'>
        {GetLabel(props)}
    </span>
)

/**
 * @param {object} props
 * @return {string} A value string for display, complete with label (or not).
 */
const GetLabel = (props) => {
    if (props.label && props.value) {
        if (props.label === '%') {
            return props.value + props.label;
        } else {
            return props.label + props.value;
        }
    } else {
        return props.value;
    }
}

export default PrintField;