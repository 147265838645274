import React from 'react';
import { connect } from 'react-redux';

/**
 * This is a critical Higher Order Component that connects a passed in field
 * to the correct state value or selector based on schema.
 */
const WrapState = (Component, field, componentProps) => {
    class Wrapper extends React.Component {
        render() {
            return (
                <Component
                    {...componentProps}
                    value={this.props.value}
                />
            )
        }
    }

    // Use a selector if there is one for this field.
    const getValue = (state) => field.selector ? field.selector(state) : state.form[field.key];

    const mapStateToProps = (state, props) => {
        return {
            value: getValue(state)
        }
    };

    return connect(mapStateToProps, null)(Wrapper);
}

export default WrapState;