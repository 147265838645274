import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import FieldValidators from './field-validators';

/**
 * This is a critical Higher Order Component that connects a passed in field
 * to the correct state value or selector, and also sets action handlers, all
 * based on schema.
 */
const WrapStateAndDispatch = (Component, field, componentProps) => {
    class Wrapper extends React.Component {

        handleChange = (event, data) => {
            this.props.handleChange(data.value);
        }

        render() {
            return (
                <div>
                    <Component
                        {...componentProps}
                        className='vtg-form-field'
                        onChange={this.handleChange}
                        value={this.props.value}
                    />

                    <FieldValidators {...field} value={this.props.value} />
                </div>
            )
        }
    }

    // Use a selector if there is one for this field.
    const getValue = (state) => field.selector ? field.selector(state) : state.form[field.key];

    const mapStateToProps = (state, props) => {
        return {
            value: getValue(state)
        }
    };

    const mapDispatchToProps = (dispatch, props) => {
        return {
            handleChange: (data) => {
                dispatch(actions.updateField(data, field.key));
            }
        }
    }

    return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
}

export default WrapStateAndDispatch;