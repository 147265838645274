import React from 'react';
import WrapState from './wrap-state';
import { FIELD } from '../constants';
import PrintField from '../components/print-field';

const FieldsPrint = (schema) => {
    return schema.map(row => {
        return row.map(field => getFieldByType(field, row.length));
    });
}

/**
 * @param {Component|string|number} component
 * @param {object} field
 * @param {number} rowLength
 * @return {Component} The passed in component (or content), wrapped in a
 * container.
 */
const getFieldContainer = (component, field, rowLength) => (
    <td
        className={`vtg-col vtg-field vtg-type-${field.type}`}
        colSpan={getColSpan(field.type, rowLength)}
        key={field.key}
    >
        {component}
    </td>  
)

/**
 * @param {string} fieldType
 * @param {number} rowLength
 * @return {number} The number of table columns a given field should span.
 * eg, If there is only on field in a row, it should be full width, etc.
 */
const getColSpan = (fieldType, rowLength) => {
    if (rowLength === 3) {
        return 1;
    }

    if (rowLength === 1) {
        return 3;
    }

    if (rowLength === 2) {
        if (fieldType === FIELD.LABEL) {
            return 1;
        } else {
            return 2
        }
    }
}

/**
 * @param {object} field
 * @param {number} rowLength
 * @return {Component} Markup is selected by field type in schema.
 */
function getFieldByType(field, rowLength) {
    let WrappedField = null;

    switch (field.type) {
        case FIELD.LABEL:
            return getFieldContainer(<span>{field.text}</span>, field, rowLength);

        case FIELD.DISPLAY:
        case FIELD.CURRENCY:
            WrappedField = WrapState(PrintField, field, {
                label: '$'
            });

            return getFieldContainer(<WrappedField />, field, rowLength);

        case FIELD.PERCENT:
            WrappedField = WrapState(PrintField, field, {
                label: '%'
            });

            return getFieldContainer(<WrappedField />, field, rowLength);

        case FIELD.SELECT:
        case FIELD.TEXT:
            WrappedField = WrapState(PrintField, field, {});

            return getFieldContainer(<WrappedField />, field, rowLength);

        case FIELD.SECTION:
            return getFieldContainer(<h2>{field.text}</h2>, field, rowLength);

        default:
            return null;
    }
}

export default FieldsPrint ;