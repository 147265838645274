import * as actions from './actions';
import { FIELD } from './constants';
import {
    escrowFee,
    escrowFeeTax,
    estimatedNetProceeds,
    exciseTax,
    titleInsurance,
    titleInsuranceTax,
    totalCosts,
    totalLoanPayoffs
} from './selectors';

export const FormSchema = [
    [
        {
            key: actions.FIRST_NAME + '_LABEL',
            text: actions.FIRST_NAME_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.FIRST_NAME,
            placeholder: 'Sam',
            type: FIELD.TEXT
        }
    ], [
        {
            key: actions.LAST_NAME + '_LABEL',
            text: actions.LAST_NAME_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.LAST_NAME,
            placeholder: 'Smith',
            type: FIELD.TEXT
        }
    ], [
        {
            key: actions.EMAIL + '_LABEL',
            text: actions.EMAIL_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.EMAIL,
            placeholder: 'sam@somewhere.com',
            type: FIELD.TEXT
        }
    ], [
        {
            key: actions.HOUSE_ADDRESS + '_LABEL',
            text: actions.HOUSE_ADDRESS_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.HOUSE_ADDRESS,
            placeholder: '1120 Somewhere Ln, Yakima, WA 98908',
            type: FIELD.TEXT
        }
    ], [
        {
            key: 'sale-information-section',
            text: 'Sale Information',
            type: FIELD.SECTION
        }
    ], [
        {
            key: actions.SALES_PRICE + '_LABEL',
            text: actions.SALES_PRICE_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.SALES_PRICE,
            placeholder: '0.00',
            type: FIELD.CURRENCY,
            validators: [
                {
                    message: 'For sale price over 1 million, please contact us directly for a quote.',
                    type: 'MAX',
                    value: 1000000
                }
            ]
        }
    ], [
        {
            key: actions.LOAN_PAYOFF + '_LABEL',
            text: actions.LOAN_PAYOFF_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.LOAN_PAYOFF,
            placeholder: '0.00',
            type: FIELD.CURRENCY
        }
    ], [
        {
            key: actions.REAL_ESTATE_COMMISSION + '_LABEL',
            text: actions.REAL_ESTATE_COMMISSION_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.REAL_ESTATE_COMMISSION_PERCENT,
            placeholder: '0',
            type: FIELD.PERCENT
        }, {
            key: actions.REAL_ESTATE_COMMISSION,
            placeholder: '0.00',
            type: FIELD.CURRENCY
        }
    ], [
        {
            key: actions.EXCISE_TAX + '_LABEL',
            text: actions.EXCISE_TAX_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.PROPERTY_LOCATION,
            placeholder: 'Select Property Location',
            options: actions.PROPERTY_LOCATION_OPTIONS,
            type: FIELD.SELECT
        }, {
            key: actions.EXCISE_TAX,
            selector: exciseTax,
            type: FIELD.DISPLAY
        }
    ], [
        {
            key: actions.ATTORNEY_DOC_PREP_FEE + '_LABEL',
            text: actions.ATTORNEY_DOC_PREP_FEE_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.ATTORNEY_DOC_PREP_FEE,
            placeholder: '0.00',
            type: FIELD.CURRENCY
        }
    ], [
        {
            key: actions.PROCESS_FEE_TO_COUNTY_TREASURER + '_LABEL',
            text: actions.PROCESS_FEE_TO_COUNTY_TREASURER_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.PROCESS_FEE_TO_COUNTY_TREASURER,
            placeholder: '0.00',
            type: FIELD.CURRENCY
        }
    ], [
        {
            key: 'title-insurance-section',
            text: 'Title Insurance',
            type: FIELD.SECTION
        }
    ], [
        {
            key: actions.TITLE_INSURANCE + '_LABEL',
            text: actions.TITLE_INSURANCE_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.TITLE_INSURANCE,
            selector: titleInsurance,
            type: FIELD.DISPLAY
        }
    ], [
        {
            key: actions.TAX_ON_TITLE_INSURANCE + '_LABEL',
            text: actions.TAX_ON_TITLE_INSURANCE_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.TAX_ON_TITLE_INSURANCE,
            selector: titleInsuranceTax,
            type: FIELD.DISPLAY
        }
    ], [
        {
            key: 'escrow-services-section',
            text: 'Escrow Services',
            type: FIELD.SECTION
        }
    ], [
        {
            key: actions.ESCROW_FEE + '_LABEL',
            text: actions.ESCROW_FEE_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.ESCROW_FEE,
            selector: escrowFee,
            type: FIELD.DISPLAY
        }
    ], [
        {
            key: actions.TAX_ON_ESCROW_FEE + '_LABEL',
            text: actions.TAX_ON_ESCROW_FEE_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.TAX_ON_ESCROW_FEE,
            selector: escrowFeeTax,
            type: FIELD.DISPLAY
        }
    ], [
        {
            key: 'miscellaneous-section',
            text: 'Miscellaneous',
            type: FIELD.SECTION
        }
    ], [
        {
            key: actions.SELLERS_CONTRIBUTION + '_LABEL',
            text: actions.SELLERS_CONTRIBUTION_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.SELLERS_CONTRIBUTION,
            placeholder: '0.00',
            type: FIELD.CURRENCY
        }
    ], [
        {
            key: actions.OTHER1 + '_LABEL',
            text: actions.OTHER1_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.OTHER1,
            placeholder: '0.00',
            type: FIELD.CURRENCY
        }
    ], [
        {
            key: actions.OTHER2 + '_LABEL',
            text: actions.OTHER2_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.OTHER2,
            placeholder: '0.00',
            type: FIELD.CURRENCY
        }
    ], [
        {
            key: 'totals-section',
            text: 'Totals',
            type: FIELD.SECTION
        }
    ], [
        {
            key: actions.ESTIMATED_TOTAL_COSTS + '_LABEL',
            text: actions.ESTIMATED_TOTAL_COSTS_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.ESTIMATED_TOTAL_COSTS,
            selector: totalCosts,
            type: FIELD.DISPLAY
        }
    ], [
        {
            key: actions.TOTAL_LOAN_PAYOFFS + '_LABEL',
            text: actions.TOTAL_LOAN_PAYOFFS_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.TOTAL_LOAN_PAYOFFS,
            selector: totalLoanPayoffs,
            type: FIELD.DISPLAY
        }
    ], [
        {
            key: actions.ESTIMATED_NET_PROCEEDS + '_LABEL',
            text: actions.ESTIMATED_NET_PROCEEDS_LABEL,
            type: FIELD.LABEL
        }, {
            key: actions.ESTIMATED_NET_PROCEEDS,
            selector: estimatedNetProceeds,
            type: FIELD.DISPLAY
        }
    ]
];