export const FIELD = {
    CURRENCY: 'CURRENCY',
    DISPLAY: 'DISPLAY',
    LABEL: 'LABEL',
    PERCENT: 'PERCENT',
    SECTION: 'SECTION',
    SELECT: 'SELECT',
    TEXT: 'TEXT'
}

export const OVER_LIMIT_FIELD_MESSAGE = 'Please contact for quote.';