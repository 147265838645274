import React from 'react';

import SellerNetForm from './components/seller-net-form';

function App() {
  return (
    <SellerNetForm />
  );
}

export default App;
