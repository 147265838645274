import React from 'react';
import FieldsPrint from '../schema-parsers/fields-print';
import LogoInline from '../svgs/logo-inline';
import Disclaimer from './disclaimer';

class PrintOut extends React.Component {
    constructor(props) {
        super(props);

        this.printBody = FieldsPrint(props.schema);
    }

    render() {
        let today = new Date();

        return (
            <div className='vtg-print-out-wrap'>
                <div className='vtg-print-out-header'>
                    <div className='vtg-print-out-header-left'>
                        <LogoInline />

                        <h2 className='vtg-print-out-title'>Estimated Sellers Net Proceeds</h2>

                        <span className='vtg-print-out-date'>{`Generated on ${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`}</span>
                    </div>

                    <div className='vtg-print-out-header-right'>
                        <div className='vtg-print-out-header-text'>502 North 2nd Street<br />Yakima, WA, 98901<br />P. (509) 248-4442</div>
                    </div>
                </div>

                <table className='vtg-print-out'>
                    <tbody>
                        {this.printBody.map((row, rowIndex) => {
                            return (
                                <tr key={`print-tr-${rowIndex}`} className={`vtg-row vtg-row-${row.length}`}>
                                    {row.map(field => field)}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <Disclaimer />
            </div>
        )
    }
};

export default PrintOut;