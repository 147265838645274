import { FIELD } from '../constants';

const FieldsEmail = (state) => {
    const schema = state.schema;
    let html = '<table width="500" cellspacing="2"><tbody>';

    schema.forEach(row => {
        html += '<tr>';

        row.forEach(field => {
            html += getFieldByType(field, row.length, state);
        });

        html += '</tr>';
    });

    return html += '</tbody></table>';
}

/**
 * @param {string} fieldType
 * @param {number} rowLength
 * @return {number} The number of table columns a given field should span.
 * eg, If there is only on field in a row, it should be full width, etc.
 */
const getColSpan = (fieldType, rowLength) => {
    if (rowLength === 3) {
        return 1;
    }

    if (rowLength === 1) {
        return 3;
    }

    if (rowLength === 2) {
        if (fieldType === FIELD.LABEL) {
            return 1;
        } else {
            return 2
        }
    }
}

/**
 * @param {object} field
 * @param {number} rowLength
 * @return {Component} Markup is selected by field type in schema.
 */
function getFieldByType(field, rowLength, state) {
    let value = null;

    switch (field.type) {
        case FIELD.LABEL:
            return `<td colspan="${getColSpan(field.type, rowLength)}">${field.text}</td>`;

        case FIELD.DISPLAY:
        case FIELD.CURRENCY:
            if (field.selector) {
                value = field.selector(state);
            } else {
                value = state.form[field.key];
            }

            return `<td colspan="${getColSpan(field.type, rowLength)}" style="text-align: right;">$${value || '0.00'}</td>`;

        case FIELD.PERCENT:
            if (field.selector) {
                value = field.selector(state);
            } else {
                value = state.form[field.key];
            }

            return `<td colspan="${getColSpan(field.type, rowLength)}" style="text-align: right;">${value}%</td>`;

        case FIELD.SELECT:
        case FIELD.TEXT:
            if (field.selector) {
                value = field.selector(state);
            } else {
                value = state.form[field.key];
            }

            return `<td colspan="${getColSpan(field.type, rowLength)}">${value}</td>`;

        case FIELD.SECTION:
            return `<td colspan="${getColSpan(field.type, rowLength)}" style="padding-top: 20px;"><h2>${field.text}</h2></td>`;

        default:
            return null;
    }
}

export default FieldsEmail;