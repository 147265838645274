import React from 'react';
import { toNumber } from '../helpers';

/**
 * @param {object} props
 * @return {Component} A list of validator messages for a field.
 */
const FieldValidators = (props) => {
    if (props.validators && props.validators.length) {
        return (
            <div className='vtg-validator-messages'>
                {props.validators.map(validator => FieldValidator(validator, props.value))}
            </div>
        )
    } else {
        return null;
    }
}

/**
 * Determines a validator condition and then returns the associated message.
 * 
 * @param {object} validator
 * @param {string} fieldValue - The value to validate.
 */
const FieldValidator = (validator, fieldValue) => {
    if (validator.type === 'MAX' && toNumber(fieldValue) > validator.value) {
        return (
            <div key={`validator-message-${validator.type}`} className={`vtg-validator-message vtg-validator-type-${validator.type}`}>
                {validator.message}
            </div>
        )
    } else {
        return null;
    }
}

export default FieldValidators;