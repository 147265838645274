import React from 'react';

const Disclaimer = (props) => (
    <div className="vtg-disclaimer">
        <p>
            <strong>Disclaimer:</strong> This form is for informational
            purposes only. Valley Title Guarantee reserves the right to
            prepare actual closing statements based on signed purchase
            and sale agreements and addendums. This information does not
            include any Homeowners Association Prorations, Property Tax
            Prorations, or assessments for irrigation, etc.
            </p>

        <p>
            This form does not provide data for homes with a sale price of
            more than two million dollars. For higher priced homes, please
            contact Valley Title Guarantee directly.
            </p>
    </div>
);

export default Disclaimer;