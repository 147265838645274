import React from 'react';
import WrapState from './wrap-state';
import WrapStateAndDispatch from './wrap-state-and-dispatch';
import { FIELD } from '../constants';
import { Dropdown, Input } from 'semantic-ui-react';
import DisplayField from '../components/display-field';
import CurrencyField from '../components/currency-field';
import PercentField from '../components/percent-field';

const FieldsForm = (schema) => {
    return schema.map(row => {
        return row.map(field => getFieldByType(field));
    });
}

/**
 * @param {object} field
 * @return {Component} A connected form field, based on field schema type.
 */
function getFieldByType(field) {
    let WrappedField = null;

    switch (field.type) {
        case FIELD.LABEL:
            return (
                <div key={field.key} className={`vtg-col vtg-field vtg-type-${field.type}`}>
                    {field.text}
                </div>
            )

        case FIELD.DISPLAY:
            WrappedField = WrapState(DisplayField, field, {
                fluid: true,
                readOnly: true,
                size: 'small'
            });

            return (
                <div key={field.key} className={`vtg-col vtg-field vtg-type-${field.type}`}>
                    <WrappedField />
                </div>
            )

        case FIELD.CURRENCY:
            WrappedField = WrapStateAndDispatch(CurrencyField, field, {
                fluid: true,
                label: '$',
                labelPosition: 'left',
                placeholder: field.placeholder,
                size: 'small'
            });

            return (
                <div key={field.key} className={`vtg-col vtg-field vtg-type-${field.type}`}>
                    <WrappedField />
                </div>
            )

        case FIELD.PERCENT:
            WrappedField = WrapStateAndDispatch(PercentField, field, {
                fluid: true,
                label: '%',
                labelPosition: 'left',
                placeholder: field.placeholder,
                size: 'small'
            });

            return (
                <div key={field.key} className={`vtg-col vtg-field vtg-type-${field.type}`}>
                    <WrappedField />
                </div>
            )

        case FIELD.SELECT:
            WrappedField = WrapStateAndDispatch(Dropdown, field, {
                fluid: true,
                options: field.options,
                placeholder: field.placeholder,
                size: 'small',
                selection: true,
            });

            return (
                <div key={field.key} className={`vtg-col vtg-field vtg-type-${field.type}`}>
                    <WrappedField />
                </div>
            )

        case FIELD.TEXT:
            WrappedField = WrapStateAndDispatch(Input, field, {
                fluid: true,
                placeholder: field.placeholder,
                size: 'small'
            });

            return (
                <div key={field.key} className={`vtg-col vtg-field vtg-type-${field.type}`}>
                    <WrappedField />
                </div>
            )

        case FIELD.SECTION:
            return (
                <div key={field.key} className={`vtg-col vtg-field vtg-type-${field.type}`}>
                    <h2>{field.text}</h2>
                </div>
            )

        default:
            return null;
    }
}

export default FieldsForm;