/* Action Types */

export const LOADER = 'LOADER';
export const LOADER_TEXT = 'LOADER_TEXT';
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_TEXT = 'MODAL_TEXT';

export const FIRST_NAME = 'FIRST_NAME';
export const LAST_NAME = 'LAST_NAME';
export const EMAIL = 'EMAIL';
export const HOUSE_ADDRESS = 'HOUSE_ADDRESS';
export const SALES_PRICE = 'SALES_PRICE';
export const LOAN_PAYOFF = 'LOAN_PAYOFF';
export const REAL_ESTATE_COMMISSION = 'REAL_ESTATE_COMMISSION';
export const REAL_ESTATE_COMMISSION_PERCENT = 'REAL_ESTATE_COMMISSION_PERCENT';
export const ATTORNEY_DOC_PREP_FEE = 'ATTORNEY_DOC_PREP_FEE';
export const PROPERTY_LOCATION = 'PROPERTY_LOCATION';
export const EXCISE_TAX = 'EXCISE_TAX_RATE';
export const PROCESS_FEE_TO_COUNTY_TREASURER = 'PROCESS_FEE_TO_COUNTY_TREASURER';
export const TITLE_INSURANCE = 'TITLE_INSURANCE';
export const TAX_ON_TITLE_INSURANCE = 'TAX_ON_TITLE_INSURANCE';
export const ESCROW_FEE = 'ESCROW_FEE';
export const TAX_ON_ESCROW_FEE = 'TAX_ON_ESCROW_FEE';
export const SELLERS_CONTRIBUTION = 'SELLERS_CONTRIBUTION';
export const OTHER1 = 'OTHER1';
export const OTHER2 = 'OTHER2';
export const ESTIMATED_TOTAL_COSTS = 'ESTIMATED_TOTAL_COSTS';
export const TOTAL_LOAN_PAYOFFS = 'TOTAL_LOAN_PAYOFFS';
export const ESTIMATED_NET_PROCEEDS = 'ESTIMATED_NET_PROCEEDS';
export const UPDATE_TOTALS = 'UPDATE_TOTALS';
export const RESET_FORM = 'RESET_FORM';

/* Other Constants */

export const FIRST_NAME_LABEL = 'First Name';
export const LAST_NAME_LABEL = 'Last Name';
export const EMAIL_LABEL = 'Email Address';
export const HOUSE_ADDRESS_LABEL = 'House Address';
export const SALES_PRICE_LABEL = 'Sales Price';
export const LOAN_PAYOFF_LABEL = 'Loan Payoff';
export const REAL_ESTATE_COMMISSION_LABEL = 'Real Estate Commission';
export const ATTORNEY_DOC_PREP_FEE_LABEL = 'Attorney Doc Prep Fee';
export const PROPERTY_LOCATION_LABEL = 'Property Location';
export const EXCISE_TAX_LABEL = 'Excise Tax';
export const PROCESS_FEE_TO_COUNTY_TREASURER_LABEL = 'Process Fee to County Treasurer';
export const TITLE_INSURANCE_LABEL = 'Title Insurance (Homeowner\'s Policy)';
export const TAX_ON_TITLE_INSURANCE_LABEL = 'Tax on Title Insurance';
export const ESCROW_FEE_LABEL = 'Escrow Fee (Seller\'s half)';
export const TAX_ON_ESCROW_FEE_LABEL = 'Tax on Escrow Fee';
export const SELLERS_CONTRIBUTION_LABEL = 'Seller\'s Contribution';
export const OTHER1_LABEL = 'Other';
export const OTHER2_LABEL = 'Other';
export const ESTIMATED_TOTAL_COSTS_LABEL = 'Estimated Total Costs';
export const TOTAL_LOAN_PAYOFFS_LABEL = 'Total Loan Payoffs';
export const ESTIMATED_NET_PROCEEDS_LABEL = 'Estimated Net Proceeds';

export const MESSAGE_ERROR_HEADER = 'Something went wrong.';
export const MESSAGE_ERROR_BODY = 'There was an error sending your results. Please check your first name, last name, and email, and then try again. If the problem persists notify Valley Title Guarantee. Thanks!';
export const MESSAGE_SUCCESS_HEADER = 'Success!';
export const MESSAGE_SUCCESS_BODY = 'Results have been sent to the email provided. You may safely leave this page, or reset the form for more estimates.'

export const TAX_RATE = 0.083;

export const PROPERTY_LOCATION_OPTIONS = [{
    key: 'county',
    text: 'County',
    value: 'county'
}, {
    key: 'city',
    text: 'City',
    value: 'city'
}];

/* Data tables used for looups. */

export const EXCISE_TAX_BRACKETS = [
    { step: 500000, county: 0.0135, city: 0.016 },
    { step: 1500000, county: 0.0153, city: 0.0178 },
    { step: 3000000, county: 0.03, city: 0.0325 },
    { step: null, county: 0.0325, city: 0.035 }
];

export const ESCROW_FEE_BRACKETS = [
    { min: 0, max: 100000, fee: 300 },
    { min: 100000.01, max: 200000, fee: 355 },
    { min: 200000.01, max: 300000, fee: 410 },
    { min: 300000.01, max: 400000, fee: 465 },
    { min: 400000.01, max: 500000, fee: 520 },
    { min: 500000.01, max: 600000, fee: 570 },
    { min: 600000.01, max: 700000, fee: 620 },
    { min: 700000.01, max: 800000, fee: 670 },
    { min: 800000.01, max: 900000, fee: 720 },
    { min: 900000.01, max: 1000000, fee: 770 },
    { min: 1000000.01, max: 1100000, fee: 795 },
    { min: 1100000.01, max: 1200000, fee: 820 },
    { min: 1200000.01, max: 1300000, fee: 870 },
    { min: 1300000.01, max: 1400000, fee: 895 },
    { min: 1400000.01, max: 1500000, fee: 920 },
    { min: 1500000.01, max: 1600000, fee: 945 },
    { min: 1600000.01, max: 1700000, fee: 970 },
    { min: 1700000.01, max: 1800000, fee: 995 },
    { min: 1800000.01, max: 1900000, fee: 1020 },
    { min: 1900000.01, max: 2000000, fee: 1045 },
    { min: 2000000.01, max: null, fee: 1045 }
];

export const TITLE_INSURANCE_BRACKETS = [
    { min: 0, max: 50000, insurance: 474.00 },
    { min: 50000.01, max: 100000, insurance: 647 },
    { min: 100000.01, max: 150000, insurance: 762 },
    { min: 150000.01, max: 200000, insurance: 878 },
    { min: 200000.01, max: 250000, insurance: 993 },
    { min: 250000.01, max: 300000, insurance: 1109 },
    { min: 300000.01, max: 350000, insurance: 1224 },
    { min: 350000.01, max: 400000, insurance: 1340 },
    { min: 400000.01, max: 450000, insurance: 1455 },
    { min: 450000.01, max: 500000, insurance: 1571 },
    { min: 500000.01, max: 550000, insurance: 1686 },
    { min: 550000.01, max: 600000, insurance: 1802 },
    { min: 600000.01, max: 650000, insurance: 1917 },
    { min: 650000.01, max: 700000, insurance: 2033 },
    { min: 700000.01, max: 750000, insurance: 2148 },
    { min: 750000.01, max: 800000, insurance: 2264 },
    { min: 800000.01, max: 850000, insurance: 2379 },
    { min: 850000.01, max: 900000, insurance: 2495 },
    { min: 900000.01, max: 950000, insurance: 2610 },
    { min: 950000.01, max: 1000000, insurance: 2726 },
    { min: 1000000.01, max: 1050000, insurance: 2810 },
    { min: 1050000.01, max: 1100000, insurance: 2893 },
    { min: 1100000.01, max: 1150000, insurance: 2977 },
    { min: 1150000.01, max: 1200000, insurance: 3061 },
    { min: 1200000.01, max: 1250000, insurance: 3144 },
    { min: 1250000.01, max: 1300000, insurance: 3228 },
    { min: 1300000.01, max: 1350000, insurance: 3312 },
    { min: 1350000.01, max: 1400000, insurance: 3396 },
    { min: 1400000.01, max: 1450000, insurance: 3479 },
    { min: 1450000.01, max: 1500000, insurance: 3563 },
    { min: 1500000.01, max: 1550000, insurance: 3647 },
    { min: 1550000.01, max: 1600000, insurance: 3731 },
    { min: 1600000.01, max: 1650000, insurance: 3814 },
    { min: 1650000.01, max: 1700000, insurance: 3898 },
    { min: 1700000.01, max: 1750000, insurance: 3982 },
    { min: 1750000.01, max: 1800000, insurance: 4066 },
    { min: 1800000.01, max: 1850000, insurance: 4149 },
    { min: 1850000.01, max: 1900000, insurance: 4233 },
    { min: 1900000.01, max: 1950000, insurance: 4317 },
    { min: 1950000.01, max: 2000000, insurance: 4401 },
    { min: 2000000.01, max: null, insurance: 4401 }
];

/* Action Creators */

/**
 * `updateField` is responsible for *all* actions fired from form fields.
 * 
 * @param {object} payload - The value emitted from fields.
 * @param {string} action - The action type, and also the key to the correct
 * form data in `state`.
 */
export function updateField(payload, action) {
    return {
        type: action,
        payload: payload
    }
}

// The rest are specific action handlers used by NOT form fields.

export function changeLoaderState(payload) {
    return {
        type: LOADER,
        payload: payload
    }
}

export function changeLoaderText(payload) {
    return {
        type: LOADER_TEXT,
        payload: payload
    }
}

export function changeModalState(payload) {
    return {
        type: MODAL_OPEN,
        payload: payload
    }
}

export function changeModalText(payload) {
    return {
        type: MODAL_TEXT,
        payload: payload
    }
}

export function resetForm() {
    return {
        type: RESET_FORM,
        payload: null
    }
}