import { createSelector } from 'reselect';
import * as actions from './actions';
import {
    addThousandSeparators,
    lookupEscrowFee,
    lookupExciseTax,
    lookupTitleInsurance,
    round2,
    roundUp2,
    toCurrency,
    toNumber
} from './helpers';
import { OVER_LIMIT_FIELD_MESSAGE } from './constants';

const attorneyDocPrepFee = (state) => state.form[actions.ATTORNEY_DOC_PREP_FEE];
const loanPayoff = (state) => state.form[actions.LOAN_PAYOFF];
const location = (state) => state.form[actions.PROPERTY_LOCATION];
const other1 = (state) => state.form[actions.OTHER1];
const other2 = (state) => state.form[actions.OTHER2];
const processFeetoCountyTreasurer = (state) => state.form[actions.PROCESS_FEE_TO_COUNTY_TREASURER];
const realEstateCommission = (state) => state.form[actions.REAL_ESTATE_COMMISSION];
const salePrice = (state) => state.form[actions.SALES_PRICE];
const sellersContribution = (state) => state.form[actions.SELLERS_CONTRIBUTION];
const overFormLimit = (state) => state.overFormLimit;

// TITLE_INSURANCE
export const titleInsurance = createSelector(
    [salePrice, overFormLimit],
    (salePrice, overFormLimit) => {
        if (overFormLimit) {
            return OVER_LIMIT_FIELD_MESSAGE;
        } else {
            salePrice = toNumber(salePrice);
            return lookupTitleInsurance(salePrice);
        }
    }
)

// TAX_ON_TITLE_INSURANCE
export const titleInsuranceTax = createSelector(
    titleInsurance,
    titleInsurance => {
        const num = toNumber(titleInsurance);
        const tax = roundUp2(num * actions.TAX_RATE).toFixed(2);
        return addThousandSeparators(tax);
    }
)

// ESCROW_FEE
export const escrowFee = createSelector(
    [salePrice, overFormLimit],
    (salePrice, overFormLimit) => {
        if (overFormLimit) {
            return OVER_LIMIT_FIELD_MESSAGE;
        } else {
            salePrice = toNumber(salePrice);
            return lookupEscrowFee(salePrice);
        }
    }
)

// TAX_ON_ESCROW_FEE
export const escrowFeeTax = createSelector(
    escrowFee,
    escrowFee => {
        const num = toNumber(escrowFee);
        const tax = roundUp2(num * actions.TAX_RATE);
        return toCurrency(tax);
    }
)

// EXCISE_TAX
export const exciseTax = createSelector(
    salePrice,
    location,
    overFormLimit,
    (salePrice, location, overFormLimit) => {
        if (overFormLimit) {
            return OVER_LIMIT_FIELD_MESSAGE;
        } else {
            salePrice = toNumber(salePrice);
            return lookupExciseTax(salePrice, location);
        }
    }
)

// ESTIMATED_TOTAL_COSTS
export const totalCosts = createSelector(
    [
        attorneyDocPrepFee,
        escrowFee,
        escrowFeeTax,
        exciseTax,
        location,
        other1,
        other2,
        processFeetoCountyTreasurer,
        realEstateCommission,
        sellersContribution,
        titleInsurance,
        titleInsuranceTax
    ],
    (
        attorneyDocPrepFee,
        escrowFee,
        escrowFeeTax,
        exciseTax,
        location,
        other1,
        other2,
        processFeetoCountyTreasurer,
        realEstateCommission,
        sellersContribution,
        titleInsurance,
        titleInsuranceTax
    ) => {
        attorneyDocPrepFee = toNumber(attorneyDocPrepFee);
        escrowFee = toNumber(escrowFee);
        escrowFeeTax = toNumber(escrowFeeTax);
        exciseTax = toNumber(exciseTax);
        location = toNumber(location);
        other1 = toNumber(other1);
        other2 = toNumber(other2);
        processFeetoCountyTreasurer = toNumber(processFeetoCountyTreasurer);
        realEstateCommission = toNumber(realEstateCommission);
        sellersContribution = toNumber(sellersContribution);
        titleInsurance = toNumber(titleInsurance);
        titleInsuranceTax = toNumber(titleInsuranceTax);

        let total = round2(
            attorneyDocPrepFee +
            escrowFee +
            escrowFeeTax +
            exciseTax +
            location +
            other1 +
            other2 +
            processFeetoCountyTreasurer +
            realEstateCommission +
            sellersContribution +
            titleInsurance +
            titleInsuranceTax
        );

        return toCurrency(total);
    }
)

// TOTAL_LOAN_PAYOFFS
export const totalLoanPayoffs = createSelector(
    loanPayoff,
    loanPayoff => toCurrency(loanPayoff)
)

// ESTIMATED_NET_PROCEEDS
export const estimatedNetProceeds = createSelector(
    [
        salePrice,
        totalCosts,
        totalLoanPayoffs
    ],
    (
        salePrice,
        totalCosts,
        totalLoanPayoffs
    ) => {
        salePrice = toNumber(salePrice);
        totalCosts = toNumber(totalCosts);
        totalLoanPayoffs = toNumber(totalLoanPayoffs);
        const proceeds = round2(salePrice - totalCosts - totalLoanPayoffs);
        return toCurrency(proceeds);
    }
)
