import React from 'react';
import { Input } from 'semantic-ui-react';

const DisplayField = (props) => {
    const handleChange = (event, data) => {

        // strip characters that aren't part of a number.
        // Negative and decimal allowed.
        let value = data.value.replace(/[^0-9.-]/g, '');

        let values = {
            formatted: value,
            raw: Number(value)
        };

        data.value = values;

        return props.onChange(event, data);
    }

    return (
        <Input
            {...props}
            onChange={(event, data) => handleChange(event, data)}
            value={props.value}
        />
    )
};

export default DisplayField;