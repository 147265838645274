import * as actions from './actions.js';
import { toNumber, addThousandSeparators, toCurrency, round3 } from './helpers';
import { FormSchema } from './form-schema';

// Default values for currency inputs are emtpy strings; they have placeholders
const defaultValues = {
    loading: false,
    loaderText: 'Sending...',
    modalOpen: false,
    messageHeader: null,
    messageBody: null,
    overFormLimit: false,
    form: {
        [actions.FIRST_NAME]: '',
        [actions.LAST_NAME]: '',
        [actions.EMAIL]: '',
        [actions.HOUSE_ADDRESS]: '',
        [actions.SALES_PRICE]: '',
        [actions.LOAN_PAYOFF]: '',
        [actions.REAL_ESTATE_COMMISSION_PERCENT]: '',
        [actions.REAL_ESTATE_COMMISSION]: '',
        [actions.ATTORNEY_DOC_PREP_FEE]: '135.00',
        [actions.PROPERTY_LOCATION]: '',
        [actions.EXCISE_TAX]: '0.00',
        [actions.PROCESS_FEE_TO_COUNTY_TREASURER]: '5.00',
        [actions.TITLE_INSURANCE]: '0.00',
        [actions.TAX_ON_TITLE_INSURANCE]: '0.00',
        [actions.ESCROW_FEE]: '0.00',
        [actions.TAX_ON_ESCROW_FEE]: '0.00',
        [actions.SELLERS_CONTRIBUTION]: '',
        [actions.OTHER1]: '',
        [actions.OTHER2]: '',
        [actions.ESTIMATED_TOTAL_COSTS]: '140.00',
        [actions.TOTAL_LOAN_PAYOFFS]: '0.00',
        [actions.ESTIMATED_NET_PROCEEDS]: '-140.00'
    },
    schema: FormSchema
};

function VtgReducers(state = defaultValues, action) {
    switch (action.type) {
        case actions.UPDATE_TOTALS:
            let totalCosts = getTotalCosts(state);
            let loanPayoffs = state.form.loanPayoff;
            let netProceeds = state.form.salesPrice - totalCosts - toNumber(loanPayoffs);

            return {
                ...state,
                form: {
                    ...state.form,
                    estimatedTotalCosts: toCurrency(totalCosts),
                    totalLoanPayoffs: toCurrency(loanPayoffs),
                    estimatedNetProceeds: toCurrency(netProceeds)
                }
            }
        case actions.FIRST_NAME:
            return {
                ...state,
                form: {
                    ...state.form,
                    [actions.FIRST_NAME]: action.payload
                }
            }
        case actions.LAST_NAME:
            return {
                ...state,
                form: {
                    ...state.form,
                    [actions.LAST_NAME]: action.payload
                }
            }
        case actions.EMAIL:
            return {
                ...state,
                form: {
                    ...state.form,
                    [actions.EMAIL]: action.payload
                }
            }
        case actions.HOUSE_ADDRESS:
            return {
                ...state,
                form: {
                    ...state.form,
                    [actions.HOUSE_ADDRESS]: action.payload
                }
            }
        case actions.SALES_PRICE:

            // Cast to number (gets rid of commas)
            const salePrice = action.payload.raw;
            let commission = state.form[actions.REAL_ESTATE_COMMISSION];
            let percent = state.form[actions.REAL_ESTATE_COMMISSION_PERCENT];

            // Limit form to values under 1 million.
            const overFormLimit = salePrice > 1000000;

            // Realestate commission
            if (percent) {
                commission = getRealEstateCommission(salePrice, percent);
            }

            if (!percent && commission) {
                percent = getRealEstateCommissionPercent(salePrice, commission);
            }

            return {
                ...state,
                overFormLimit: overFormLimit,
                form: {
                    ...state.form,
                    [actions.SALES_PRICE]: action.payload.formatted,
                    [actions.REAL_ESTATE_COMMISSION]: commission,
                    [actions.REAL_ESTATE_COMMISSION_PERCENT]: percent
                }
            }
        case actions.LOAN_PAYOFF:
            return {
                ...state,
                form: {
                    ...state.form,
                    [actions.LOAN_PAYOFF]: action.payload.formatted
                }
            }
        case actions.REAL_ESTATE_COMMISSION_PERCENT:
            return {
                ...state,
                form: {
                    ...state.form,
                    [actions.REAL_ESTATE_COMMISSION_PERCENT]: action.payload.formatted,
                    [actions.REAL_ESTATE_COMMISSION]: getRealEstateCommission(state.form[actions.SALES_PRICE], action.payload.raw)
                }
            }
        case actions.REAL_ESTATE_COMMISSION:
            return {
                ...state,
                form: {
                    ...state.form,
                    [actions.REAL_ESTATE_COMMISSION_PERCENT]: getRealEstateCommissionPercent(state.form[actions.SALES_PRICE], action.payload.raw),
                    [actions.REAL_ESTATE_COMMISSION]: action.payload.formatted
                }
            }
        case actions.ATTORNEY_DOC_PREP_FEE:
            return {
                ...state,
                form: {
                    ...state.form,
                    [actions.ATTORNEY_DOC_PREP_FEE]: action.payload.formatted
                }
            }
        case actions.PROPERTY_LOCATION:
            return {
                ...state,
                form: {
                    ...state.form,
                    [actions.PROPERTY_LOCATION]: action.payload
                }
            }
        case actions.PROCESS_FEE_TO_COUNTY_TREASURER:
            return {
                ...state,
                form: {
                    ...state.form,
                    [actions.PROCESS_FEE_TO_COUNTY_TREASURER]: action.payload.formatted
                }
            }
        case actions.SELLERS_CONTRIBUTION:
            return {
                ...state,
                form: {
                    ...state.form,
                    [actions.SELLERS_CONTRIBUTION]: action.payload.formatted
                }
            }
        case actions.OTHER1:
            return {
                ...state,
                form: {
                    ...state.form,
                    [actions.OTHER1]: action.payload.formatted
                }
            }
        case actions.OTHER2:
            return {
                ...state,
                form: {
                    ...state.form,
                    [actions.OTHER2]: action.payload.formatted
                }
            }
        case actions.LOADER:
            return {
                ...state,
                loading: action.payload
            }
        case actions.LOADER_TEXT:
            return {
                ...state,
                loaderText: action.payload
            }
        case actions.MODAL_OPEN:
            return {
                ...state,
                modalOpen: action.payload
            }
        case actions.MODAL_TEXT:
            return {
                ...state,
                messageHeader: action.payload.messageHeader || state.messageHeader,
                messageBody: action.payload.messageBody || state.messageBody
            }
        case actions.RESET_FORM:
            return defaultValues;
        default:
            return state;
    }
}

/**
 * REAL_ESTATE_COMMISSION_PERCENT
 * 
 * @param {string|number} price
 * @param {string|number} commission
 * @return {string} Percent, commission as a fraction of sale price.
 */
export const getRealEstateCommissionPercent = (price, commission) => {
    price = toNumber(price);
    commission = toNumber(commission);

    // Since we're dividing, if we get two zeros we'll end up with NaN.
    if (price && commission) {
        return String(round3(commission / price * 100)) || 0;
    } else {
        return '0';
    }
}

/**
 * REAL_ESTATE_COMMISSION
 * 
 * @param {string|number} price
 * @param {string|number} percent
 * @return {string} Dollar amount, commission as a percentage of sale price.
 */
export const getRealEstateCommission = (price, percent) => {
    price = toNumber(price);
    percent = toNumber(percent);
    const commission = (price * percent / 100).toFixed(2);
    return addThousandSeparators(commission);
}

/**
 * @param {object} state
 * 
 * @return {number} The total sum of all relevant form amounts.
 */
function getTotalCosts(state) {
    return (
        toNumber(state.form.attorneyDocPrepFee)
        + toNumber(state.form.realEstateCommission)
        + toNumber(state.form.exciseTax)
        + toNumber(state.form.processFeetoCountyTreasurer)
        + toNumber(state.form.titleInsurance)
        + toNumber(state.form.taxOnTitleInsurance)
        + toNumber(state.form.escrowFee)
        + toNumber(state.form.taxOnEscrowFee)
        + toNumber(state.form.sellersContribution)
        + toNumber(state.form.other1)
        + toNumber(state.form.other2)
    );
}

export default VtgReducers;