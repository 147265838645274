import * as actions from './actions';

/**
 * @param {number} num
 * 
 * @return {number} Rounded to the nearest two decimal places.
 */
export function round2(num) {
    return (Math.round(num * 100) / 100);
}

/**
 * @param {number} num
 * 
 * @return {number} Rounded to the nearest two decimal places.
 */
export function round3(num) {
    return (Math.round(num * 1000) / 1000);
}

/**
 * @param {number} num
 * 
 * @return {number} Rounded up to the nearest two decimals.
 */
export function roundUp2(num) {
    return Number(Math.round(num + 'e' + 2) + 'e-' + 2);
}

/**
 * Strip commas from string.
 * 
 * @param {String} string
 */
export function stripCommas(string) {
    return string.replace(/,/g, '');
}

/**
 * @param {number|string} num
 * 
 * @return {string} Number in string format with thousands separators (commas).
 */
export function addThousandSeparators(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

/**
 * @param {val} string
 * 
 * @return {number}
 */
export function toNumber(val) {

    // Ignore if already a number
    if (typeof val === 'number') {
        return val;
    }
    
    return parseFloat(stripCommas(val)) || 0;
}

/**
 * @param {number} num
 * 
 * @return {string} Formatted title insurance amount.
 */
export const lookupTitleInsurance = num => {
    let brackets = actions.TITLE_INSURANCE_BRACKETS;
    let insurance = 0;

    for (let i = 0; i < brackets.length; i++) {
        let bracket = brackets[i];

        if (num >= bracket.min && num <= bracket.max) {
            insurance =  bracket.insurance;
        } else if (num >= bracket.min && !bracket.max) {
            insurance = bracket.insurance;
        }
    }

    return addThousandSeparators(insurance.toFixed(2));
}

/**
 * @param {number} num
 * @return {string} Formatted escrow fee amount.
 */
export const lookupEscrowFee = num => {
    let brackets = actions.ESCROW_FEE_BRACKETS;
    let fee = 0;

    for (let i = 0; i < brackets.length; i++) {
        let bracket = brackets[i];

        if (num >= bracket.min && num <= bracket.max) {
            fee = bracket.fee;
        } else if (num >= bracket.min && !bracket.max) {
            fee = bracket.fee;
        }
    }

    return addThousandSeparators(fee.toFixed(2));
}

/**
 * @param {number} price - The amount to get tax on.
 * @param {string} location - County or City
 * @return {string} The Excise Tax lookup result for a given amount.
 */
export const lookupExciseTax= (num, location) => {
    let brackets = actions.EXCISE_TAX_BRACKETS;
    let taxAmount = 0;

    // We need a location to determine the rate.
    // We also need a defined amount.
    if (!location || !num) {
        return '';
    }

    for (let i = 0; i < brackets.length; i++) {
        let bracket = brackets[i];
        let lastStep = (brackets[i - 1] && brackets[i - 1].step) || 0;

        if (num < bracket.step) {

            // Early return if the amount is within the first bracket only.
            if (i === 0) {
                return addThousandSeparators((num * bracket[location]).toFixed(2));
            }

            taxAmount += (num - lastStep) * bracket[location];

            break;

        } else {

            if (bracket.step) {
                taxAmount += (bracket.step - lastStep) * bracket[location];
            } else {
                taxAmount += (num - lastStep) * bracket[location];
            }
        }
    }

    return addThousandSeparators(taxAmount.toFixed(2));
}

/**
 * The following functions help `toCurrency` with formatting for currency.
 */

const getDigitsFromValue = (value = '') => value.replace(/(-(?!\d))|[^0-9|-]/g, '') || ''

const padDigits = digits => {
    const desiredLength = 3
    const actualLength = digits.length

    if (actualLength >= desiredLength) {
        return digits
    }

    const amountToAdd = desiredLength - actualLength
    const padding = '0'.repeat(amountToAdd)

    return padding + digits
}

const removeLeadingZeros = number => number.replace(/^0+([0-9]+)/, '$1')

const addDecimalToNumber = (number, separator) => {
    const centsStartingPosition = number.length - 2
    const dollars = removeLeadingZeros(
        number.substring(0, centsStartingPosition)
    )
    const cents = number.substring(centsStartingPosition)
    return dollars + separator + cents
}

/**
 * @param {string|number} value
 * @param {string} separator
 */
export const toCurrency = (value, separator = '.') => {

    // Make sure numbers get converted to string with two decimal places.
    if (typeof value === 'number') {
        value = value.toFixed(2);
    }

    const digits = getDigitsFromValue(value);
    const digitsWithPadding = padDigits(digits);
    const digitsWithDecimal = addDecimalToNumber(digitsWithPadding, separator);
    return addThousandSeparators(digitsWithDecimal);
}